<template>
    <div>
        <div class="page-header hidden-sm-and-down">
            <div style="float: right">
            </div>
            <el-page-header @back="$router.go(-1)" content="用户设置" />
        </div>
        <el-card shadow="hover" style="margin:20px 0" :body-style="{ padding: '0 20px' }">
            <div slot="header" class="clearfix">
                用户列表
                <el-input style="float: right; padding: 0;width:200px" size="mini" placeholder="请输入内容"
                    prefix-icon="el-icon-search" v-model="keywords">
                </el-input>
            </div>
            <el-table :data="tableData" stripe style="width: 100%;" height="400">
                <el-table-column type="index" width="60" />
                <el-table-column prop="nickname" label="用户角色" width="180" />
                <el-table-column prop="username" label="用户名" width="" />
                <el-table-column prop="created" label="创建时间" width="" />
                <el-table-column prop="desc" label="备注" width="" />
                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <el-button size="mini" @click="dialogVisible = true">设置权限</el-button>
                        <el-button size="mini" @click="passwordVisible = true">重置密码</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
        <el-dialog title="设置权限" :visible.sync="dialogVisible" width="30%">
            <el-form ref="permissionForm" :model="permissionForm" label-width="80px">
                <el-form-item label="访问权限">
                    <el-checkbox-group v-model="permissionForm.modules">
                        <el-checkbox label="故障预测" name="modules"></el-checkbox>
                        <el-checkbox label="测试数据" name="modules"></el-checkbox>
                        <el-checkbox label="测试结果" name="modules"></el-checkbox>
                        <el-checkbox label="系统设置" name="modules"></el-checkbox>
                        <el-checkbox label="文档支持" name="modules"></el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item label="操作权限">
                    <el-checkbox-group v-model="permissionForm.actions">
                        <el-checkbox label="查询" name="actions"></el-checkbox>
                        <el-checkbox label="添加" name="actions"></el-checkbox>
                        <el-checkbox label="修改" name="actions"></el-checkbox>
                        <el-checkbox label="删除" name="actions"></el-checkbox>
                        <el-checkbox label="导出" name="actions"></el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog title="重置密码" :visible.sync="passwordVisible" width="30%">
            <el-form ref="passwordForm" :model="passwordForm" label-width="80px">
                <el-form-item label="原密码">
                    <el-input v-model="passwordForm.password"></el-input>
                </el-form-item>
                <el-form-item label="新密码">
                    <el-input v-model="passwordForm.new_password1"></el-input>
                </el-form-item>
                <el-form-item label="确认密码">
                    <el-input v-model="passwordForm.new_password2"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="passwordVisible = false">取 消</el-button>
                <el-button type="primary" @click="passwordVisible = false">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {
            loading: false,
            dialogVisible: false,
            passwordVisible: false,
            keywords: '',
            tableData: [],
            permissionForm: { modules: [], actions: [] },
            passwordForm: {}
        }
    },
    methods: {
        async getData() {
            this.loading = false
            const resp = await this.$http.get('/user')
            this.tableData = resp.data.data.records
            this.loading = false
        },
    },

    mounted() {
        this.getData()
    },
}
</script>

